"use client";

import { Link } from "@/components/Link/Link.component";
import { useTranslation } from "@/components/Translation/hooks/use-translation.hook";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import clsx from "clsx";
import Image from "next/image";
type TopAdFallbackProps = {
  readonly className?: string;
};
export function TopAdFallback({
  className
}: TopAdFallbackProps) {
  const {
    id,
    links: {
      fallbackBanner
    }
  } = useSiteConfiguration();
  const label = useTranslation<string>({
    da: "Annonce",
    de: "Anzeige",
    en: "Advertisement",
    no: "Annonse",
    sv: "Annons"
  });
  const imgSource = {
    desktop: `/images/topbanner-fallbacks/${id}_desktop.png`,
    mobile: `/images/topbanner-fallbacks/${id}_mobile.png`
  };
  if (fallbackBanner === undefined) return null;
  return <div className={clsx(className, "relative", "before:absolute before:-top-2 before:left-0 before:select-none before:text-xs before:uppercase before:leading-tight before:text-silver before:content-[attr(data-label)]")} data-label={label} data-sentry-component="TopAdFallback" data-sentry-source-file="TopAdFallback.component.tsx">
      <Link className="block" href={fallbackBanner} data-sentry-element="Link" data-sentry-source-file="TopAdFallback.component.tsx">
        <picture className="relative block h-[160px] w-[320px] lg:h-[180px] lg:w-[930px]" data-sentry-element="picture" data-sentry-source-file="TopAdFallback.component.tsx">
          <source media={`(min-width: ${tailwindScreenSize.md})`} sizes="930px" srcSet={imgSource.desktop} />
          <source media="(min-width: 0)" srcSet={imgSource.mobile} />
          <Image alt={label} className="select-none object-cover" draggable={false} fill priority sizes="320px" src={imgSource.mobile} data-sentry-element="Image" data-sentry-source-file="TopAdFallback.component.tsx" />
        </picture>
      </Link>
    </div>;
}